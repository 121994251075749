import registerConfig from '@avue/avue-data/registerConfig';
const install = (Vue, ops) => {
  const AvueData = () => import('@avue/avue-data/page/view')
  Vue.component('avue-data', AvueData)
}

// 如果是直接引入的
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default {
  install,
  registerConfig
}
export {
  install,
  registerConfig
}